var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-sheet',_vm._b({staticClass:"form-short-text pa-3 fullwidth d-flex flex-column align-center justify-start"},'v-sheet',_vm.$attrs,false),[_c('label',{staticClass:"align-self-start mb-1"},[_c('ContentEditable',{staticClass:"fw-500 fs-15",style:(("color:" + _vm.label_color + ";")),attrs:{"editable":false,"show-edit":false,"value":_vm.structures.label}})],1),_c('v-row',{staticClass:"fullwidth",attrs:{"dense":"","align":"center","justify":"start"}},[_c('v-col',{staticClass:"d-flex fullwidth align-center justify-center",attrs:{"cols":"12"}},[(_vm.structures.type === 'text')?_c('v-text-field',{attrs:{"hide-details":"auto","outlined":"","dense":"","rules":[_vm.structures.required ? _vm.requiredRule() : _vm.optionalRule()],"type":_vm.structures.type,"placeholder":_vm.structures.placeholder,"readonly":_vm.readOnly},model:{value:(_vm.fieldValue),callback:function ($$v) {_vm.fieldValue=$$v},expression:"fieldValue"}}):(_vm.structures.type === 'number')?_c('v-text-field',{attrs:{"hide-details":"auto","outlined":"","dense":"","min":_vm.structures.min,"max":_vm.structures.max,"rules":[
          _vm.structures.required ? _vm.requiredRule() : _vm.optionalRule(),
          _vm.numericRule(),
          _vm.minRule(_vm.structures.min),
          _vm.maxRule(_vm.structures.max)
        ],"type":_vm.structures.type,"placeholder":_vm.structures.placeholder,"readonly":_vm.readOnly},model:{value:(_vm.fieldValue),callback:function ($$v) {_vm.fieldValue=$$v},expression:"fieldValue"}}):(_vm.structures.type === 'email')?_c('v-text-field',{attrs:{"hide-details":"auto","outlined":"","dense":"","rules":[
          _vm.structures.required ? _vm.requiredRule() : _vm.optionalRule(),
          _vm.emailRule()
        ],"type":_vm.structures.type,"placeholder":_vm.structures.placeholder,"readonly":_vm.readOnly},model:{value:(_vm.fieldValue),callback:function ($$v) {_vm.fieldValue=$$v},expression:"fieldValue"}}):(_vm.structures.type === 'url')?_c('v-text-field',{attrs:{"hide-details":"auto","outlined":"","dense":"","rules":[
          _vm.structures.required ? _vm.requiredRule() : _vm.optionalRule(),
          _vm.urlRule()
        ],"type":_vm.structures.type,"placeholder":_vm.structures.placeholder,"readonly":_vm.readOnly},model:{value:(_vm.fieldValue),callback:function ($$v) {_vm.fieldValue=$$v},expression:"fieldValue"}}):_vm._e()],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }