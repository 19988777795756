<template>
  <v-sheet
    v-bind="$attrs"
    class="question-multiple-choice pa-3 fullwidth d-flex flex-column align-center justify-start"
  >
    <label class="align-self-start d-flex align-center justify-start mb-1">
      <ContentEditable
        :editable="false"
        :show-edit="false"
        :style="`color:${label_color};`"
        class="fw-500 fs-15"
        v-model="structures.label"
      ></ContentEditable>
      <span v-if="requiredButNoSelected" class="ml-5 red--text fw-400">
        (Required atleast one selected)
      </span>
    </label>
    <v-row dense align="center" justify="space-between" class="py-2 fullwidth">
      <v-col
        v-if="structures.multiple"
        cols="12"
        class="d-flex fullwidth align-center justify-start"
        :class="{ 'flex-column': structures.direction !== 'row' }"
      >
        <div
          class="d-flex align-center justify-start"
          :class="{ fullwidth: structures.direction !== 'row' }"
          v-for="(item, index) in structures.items"
          :key="index"
        >
          <v-checkbox
            hide-details="auto"
            class="ma-1"
            :value="item"
            v-model="fieldValue"
            :key="`${index}-${item}`"
            :label="item"
          >
          </v-checkbox>
        </div>
      </v-col>
      <v-col
        v-else
        cols="12"
        class="d-flex fullwidth align-center justify-start"
      >
        <v-radio-group
          v-model="fieldValue"
          :row="structures.direction === 'row'"
          :mandatory="structures.required"
          class="mt-0"
          hide-details="auto"
        >
          <v-radio
            v-for="(item, index) in structures.items"
            class="ma-1 primary--text fs-16 fw-500"
            :value="item"
            :key="index"
            :label="item"
          ></v-radio>
        </v-radio-group>
      </v-col>
    </v-row>
  </v-sheet>
</template>

<script>
import _ from 'lodash'
import ContentEditable from '@/common/Forms/ContentEditable.vue'
import { mapGetters } from 'vuex'
export default {
  components: { ContentEditable },
  inheritAttrs: false,
  name: 'QuestionMultipleChoice',
  props: {
    sectionIndex: { type: Number, default: 0 },
    fieldIndex: { type: Number, default: 0 }
  },
  computed: {
    ...mapGetters('forms', ['label_color']),
    requiredButNoSelected() {
      if (!this.structures) return false
      if (!this.structures.required) return false
      return (
        this.structures.required &&
        (!this.fieldValue || this.fieldValue.length === 0)
      )
    },
    structures() {
      return this.$store.getters['forms/get_section_items_by_indexes'](
        this.sectionIndex,
        this.fieldIndex
      )
    },
    fieldValue: {
      get() {
        return this.structures.value
      },
      set(val) {
        this.$store.commit('forms/update_section_item_prop', {
          sx: this.sectionIndex,
          ix: this.fieldIndex,
          prp: 'value',
          val: val
        })
      }
    }
  },
  created() {
    if (this.structures) {
      this.fieldValue = this.structures.multiple ? [] : null
      if (this.structures.required) {
        this.fieldValue = this.structures.multiple
          ? [this.structures.items[0]]
          : this.structures.items[0]
      }
    }
  }
}
</script>

<style lang="scss">
.question-multiple-choice {
  .v-radio {
    label {
      color: $blue;
      font-size: 16px;
      font-weight: 500;
    }
  }

  .v-input--checkbox {
    label {
      color: $blue;
      font-size: 16px;
      font-weight: 500;
    }
  }
}
</style>
