<template>
  <v-sheet v-bind="$attrs" class="pa-3 fullwidth d-flex flex-column">
    <v-row dense align="center" justify="space-between">
      <v-col
        cols="12"
        md="12"
        sm="12"
        class="fullwidth d-flex align-center"
        :key="structures.src"
      >
        <iframe
          v-if="isYoutube"
          style="max-width: 100%"
          :class="alignClass"
          class="d-block"
          allowusermedia
          allowfullscreen
          :src="embed"
          :width="structures.itemwidth"
          :height="structures.itemheight"
        ></iframe>
        <LazyVideo
          v-else
          :width="structures.itemwidth"
          :height="structures.itemheight"
          :src="structures.src"
          style="max-width: 100%; max-height: 600px"
          :class="alignClass"
        ></LazyVideo>
      </v-col>
    </v-row>
  </v-sheet>
</template>

<script>
export default {
  inheritAttrs: false,
  name: 'ShowVideo',
  props: {
    sectionIndex: { type: Number, default: 0 },
    fieldIndex: { type: Number, default: 0 },
    item: Object
  },
  computed: {
    structures() {
      if (this.item) return this.item
      return this.$store.getters['forms/get_section_items_by_indexes'](
        this.sectionIndex,
        this.fieldIndex
      )
    },
    isYoutube() {
      const regex =
        /^(?:https?:\/\/)?(?:www\.)?(?:youtube\.com|youtu\.be)\/watch\?v=([^&]+)/m
      return regex.test(this.structures.src)
    },
    embed() {
      if (!this.structures) return ''
      let youtubeID = this.structures.src
        ? this.youtubeParser(this.structures.src)
        : ''
      return `https://www.youtube.com/embed/${youtubeID}`
    },
    alignClass() {
      if (this.structures && this.structures.align === 'right') return 'ml-auto'
      else if (this.structures && this.structures.align === 'center')
        return 'mx-auto'
      else return 'mr-auto'
    }
  }
}
</script>

<style></style>
