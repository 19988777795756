<template>
  <v-card
    flat
    outlined
    :class="structures.id"
    v-bind="$attrs"
    v-if="structures && is_condition_applicable"
    class="rounded-tl-10 form-section rounded-tr-10 mb-5"
  >
    <v-card-title
      :style="`background-color:${theme_color};`"
      class="py-1 mb-1 rounded-tl-8 rounded-tr-8"
    ></v-card-title>
    <v-card-text class="d-block">
      <div class="d-block" v-if="structures.value.length">
        <template v-for="(form, index) in structures.value">
          <QuestionShortText
            v-if="'FormShortText' === form.tag"
            :section-index="value"
            :field-index="index"
            :key="form.id"
          ></QuestionShortText>
          <QuestionParagraph
            v-else-if="'FormParagraph' === form.tag"
            :section-index="value"
            :field-index="index"
            :key="form.id"
          ></QuestionParagraph>
          <QuestionFileUpload
            v-else-if="'FormFileUpload' === form.tag"
            :section-index="value"
            :field-index="index"
            :key="form.id"
          ></QuestionFileUpload>
          <QuestionDropdown
            v-else-if="'FormDropdown' === form.tag"
            :section-index="value"
            :field-index="index"
            :key="form.id"
          ></QuestionDropdown>
          <QuestionCheckbox
            v-else-if="'FormCheckbox' === form.tag"
            :section-index="value"
            :field-index="index"
            :key="form.id"
            @valid="validation_array[index] = $event"
          ></QuestionCheckbox>
          <QuestionMultipleChoice
            v-else-if="'FormMultipleChoice' === form.tag"
            :section-index="value"
            :field-index="index"
            :key="form.id"
          ></QuestionMultipleChoice>
          <QuestionDate
            v-else-if="'FormDate' === form.tag"
            :section-index="value"
            :field-index="index"
            :key="form.id"
          ></QuestionDate>
          <QuestionTime
            v-else-if="'FormTime' === form.tag"
            :section-index="value"
            :field-index="index"
            :key="form.id"
          ></QuestionTime>
          <ShowVideo
            v-else-if="'DisplayVideo' === form.tag"
            :section-index="value"
            :field-index="index"
            :key="form.id"
          ></ShowVideo>
          <ShowText
            v-else-if="'DisplayText' === form.tag"
            :section-index="value"
            :field-index="index"
            :key="form.id"
          ></ShowText>
          <ShowImage
            v-else-if="'DisplayImage' === form.tag"
            :section-index="value"
            :field-index="index"
            :key="form.id"
          ></ShowImage>
        </template>
      </div>
    </v-card-text>
  </v-card>
</template>

<script>
import _ from 'lodash'
import ShowVideo from '@/modules/Forms-Online/components/ShowVideo.vue'
import ShowText from '@/modules/Forms-Online/components/ShowText.vue'
import ShowImage from '@/modules/Forms-Online/components/ShowImage.vue'
import QuestionShortText from '@/modules/Forms-Online/components/QuestionShortText.vue'
import QuestionParagraph from '@/modules/Forms-Online/components/QuestionParagraph.vue'
import QuestionFileUpload from '@/modules/Forms-Online/components/QuestionFileUpload.vue'
import QuestionDropdown from '@/modules/Forms-Online/components/QuestionDropdown.vue'
import QuestionCheckbox from '@/modules/Forms-Online/components/QuestionCheckbox.vue'
import QuestionMultipleChoice from '@/modules/Forms-Online/components/QuestionMultipleChoice.vue'
import QuestionDate from '@/modules/Forms-Online/components/QuestionDate.vue'
import QuestionTime from '@/modules/Forms-Online/components/QuestionTime.vue'
import ContentEditable from '@/common/Forms/ContentEditable.vue'
import { mapGetters, mapMutations } from 'vuex'
import jsonLogic from 'json-logic-js'

export default {
  components: {
    ContentEditable,
    ShowVideo,
    ShowText,
    ShowImage,
    QuestionShortText,
    QuestionParagraph,
    QuestionFileUpload,
    QuestionDropdown,
    QuestionCheckbox,
    QuestionMultipleChoice,
    QuestionDate,
    QuestionTime
  },
  inheritAttrs: false,
  name: 'QuestionSection',
  props: {
    value: Number
  },
  data: () => ({
    validation_array: [],
    structures: null
  }),
  computed: {
    ...mapGetters('forms', ['theme_color', 'get_watch_values']),
    is_condition_applicable() {
      if (!this.structures.conditions) return jsonLogic.apply(true, {})
      return jsonLogic.apply(
        {
          [this.structures.conditions.connector]: _.cloneDeep(
            this.structures.conditions.children
          ).map((item) => {
            return {
              [item['operator']]: [{ var: item['variable'] }, item['compared']]
            }
          })
        },
        this.get_watch_values
      )
    }
  },
  watch: {
    value: {
      handler: function (val) {
        this.structures = this.$store.getters['forms/get_section_by_index'](val)
      },
      immediate: true
    },
    structures: {
      handler: function (val) {
        //this.$emit('input', val)
      },
      deep: true,
      immediate: true
    }
  },
  methods: {
    ...mapMutations('forms', ['replace_section_item']),
    updateWith(form, index) {
      this.replace_section_item({
        sIndex: this.value,
        iIndex: index,
        payload: form
      })
    }
  }
}
</script>

<style lang="scss" scoped></style>
