<template>
  <v-sheet
    v-bind="$attrs"
    class="pa-3 fullwidth d-flex flex-column align-center justify-start"
  >
    <label class="align-self-start mb-1">
      <ContentEditable
        :editable="false"
        :show-edit="false"
        :style="`color:${label_color};`"
        class="fw-500 fs-15"
        :value="structures.label"
      ></ContentEditable>
    </label>
    <v-row dense align="center" justify="start" class="fullwidth">
      <v-col cols="12" class="d-flex fullwidth align-center justify-center">
        <v-text-field
          :value="fieldValue"
          clearable
          @click:clear="fieldValue = null"
          hide-details="auto"
          @click="modal = true"
          append-icon="mdi-clock"
          readonly
          outlined
          dense
        ></v-text-field>
      </v-col>
    </v-row>
    <v-dialog
      ref="dialog"
      v-model="modal"
      :return-value.sync="fieldValue"
      persistent
      width="300px"
    >
      <v-time-picker
        v-model="time"
        :min="structures.min_time"
        :max="structures.max_time"
        full-width
        ampm-in-title
      >
        <v-spacer></v-spacer>
        <v-btn text color="primary" @click="modal = false"> Cancel </v-btn>
        <v-btn text color="primary" @click="$refs.dialog.save(time)">
          OK
        </v-btn>
      </v-time-picker>
    </v-dialog>
  </v-sheet>
</template>

<script>
import _ from 'lodash'
import ContentEditable from '@/common/Forms/ContentEditable.vue'
import { mapGetters } from 'vuex'
export default {
  components: { ContentEditable },
  inheritAttrs: false,
  name: 'QuestionTime',
  props: {
    sectionIndex: { type: Number, default: 0 },
    fieldIndex: { type: Number, default: 0 }
  },
  data: () => ({
    modal: false,
    time: null
  }),
  computed: {
    ...mapGetters('forms', ['label_color']),
    structures() {
      return this.$store.getters['forms/get_section_items_by_indexes'](
        this.sectionIndex,
        this.fieldIndex
      )
    },
    fieldValue: {
      get() {
        return this.structures.value
      },
      set(val) {
        this.$store.commit('forms/update_section_item_prop', {
          sx: this.sectionIndex,
          ix: this.fieldIndex,
          prp: 'value',
          val: val
        })
      }
    }
  }
}
</script>

<style lang="scss" scoped></style>
