<template>
  <v-sheet v-bind="$attrs" class="pa-3 fullwidth d-flex flex-column">
    <v-row dense align="center" justify="space-between">
      <v-col cols="12" md="12" sm="11" class="fullwidth d-flex align-center">
        <ContentEditable
          style="min-height: 10px; min-width: 10px"
          :style="`color:${structures.font_color}`"
          :class="[
            alignClass,
            `fw-${structures.font_weight}`,
            `fs-${structures.font_size}`
          ]"
          :editable="false"
          :show-edit="false"
          :value="structures.text"
        ></ContentEditable>
      </v-col>
    </v-row>
  </v-sheet>
</template>

<script>
import _ from 'lodash'
import ContentEditable from '@/common/Forms/ContentEditable.vue'
export default {
  components: { ContentEditable },
  inheritAttrs: false,
  name: 'ShowText',
  props: {
    sectionIndex: { type: Number, default: 0 },
    fieldIndex: { type: Number, default: 0 },
    item: Object
  },
  data: () => ({
    showMenu: false
  }),
  computed: {
    structures() {
      if (this.item) return this.item
      return this.$store.getters['forms/get_section_items_by_indexes'](
        this.sectionIndex,
        this.fieldIndex
      )
    },
    alignClass() {
      if (this.structures && this.structures.align === 'right') return 'ml-auto'
      else if (this.structures && this.structures.align === 'center')
        return 'mx-auto'
      else return 'mr-auto'
    }
  }
}
</script>

<style></style>
