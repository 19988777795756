<template>
  <v-sheet
    v-bind="$attrs"
    class="pa-3 fullwidth d-flex flex-column align-center justify-start"
  >
    <label class="align-self-start mb-1">
      <ContentEditable
        :editable="false"
        :show-edit="false"
        :style="`color:${label_color};`"
        class="fw-500 fs-15"
        v-model="structures.label"
      ></ContentEditable>
    </label>
    <v-row dense align="center" justify="start" class="fullwidth">
      <v-col cols="12" class="d-flex fullwidth align-center justify-center">
        <v-select
          hide-details="auto"
          outlined
          dense
          clearable
          chips
          :rules="structures.required ? [requiredRule()] : []"
          v-model="fieldValue"
          small-chips
          :items="structures.items"
          :multiple="structures.multiple"
          :key="structures.items.join(',')"
          :placeholder="structures.placeholder"
        ></v-select>
      </v-col>
    </v-row>
  </v-sheet>
</template>

<script>
import _ from 'lodash'
import ContentEditable from '@/common/Forms/ContentEditable.vue'
import { mapGetters } from 'vuex'
export default {
  components: { ContentEditable },
  inheritAttrs: false,
  name: 'QuestionDropDown',
  props: {
    sectionIndex: { type: Number, default: 0 },
    fieldIndex: { type: Number, default: 0 }
  },
  data: () => ({
    showMenu: false
  }),
  computed: {
    ...mapGetters('forms', ['label_color']),
    structures() {
      return this.$store.getters['forms/get_section_items_by_indexes'](
        this.sectionIndex,
        this.fieldIndex
      )
    },
    fieldValue: {
      get() {
        return this.structures.value
      },
      set(val) {
        this.$store.commit('forms/update_section_item_prop', {
          sx: this.sectionIndex,
          ix: this.fieldIndex,
          prp: 'value',
          val: val
        })
      }
    }
  }
}
</script>

<style lang="scss" scoped></style>
