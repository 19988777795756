<template>
  <v-sheet
    v-bind="$attrs"
    class="pa-3 fullwidth d-flex flex-column align-center justify-start"
  >
    <label class="align-self-start mb-1">
      <ContentEditable
        :editable="false"
        :show-edit="false"
        :style="`color:${label_color};`"
        class="fw-500 fs-15"
        :value="structures.label"
      ></ContentEditable>
    </label>
    <v-row dense align="center" justify="start" class="fullwidth">
      <v-col cols="12" class="d-flex fullwidth align-center justify-center">
        <v-file-input
          hide-details="auto"
          outlined
          dense
          type="file"
          chips
          :disabled="loading"
          clearable
          @click:clear="clearInput"
          v-model="fileinput"
          :rules="[
            structures.required ? requiredRule() : true,
            validFileSizeRule(),
            validFileExtOrTypeRule()
          ]"
          :multiple="structures.multiple"
          :key="structures.id"
          :accept="acceptedTypes"
          prepend-icon=""
          :loading="loading"
          append-icon="mdi-cloud-upload"
          :placeholder="structures.placeholder"
        >
          <template v-slot:progress>
            <div class="d-flex pl-2 align-center justify-center">
              Loading <v-icon right>mdi-loading mdi-spin</v-icon>
            </div>
          </template>
        </v-file-input>
      </v-col>
    </v-row>
  </v-sheet>
</template>

<script>
import _ from 'lodash'
import request from '@/services/axios_instance'
import ContentEditable from '@/common/Forms/ContentEditable.vue'
import { mapGetters } from 'vuex'
export default {
  components: { ContentEditable },
  inheritAttrs: false,
  name: 'QuestionFileUpload',
  props: {
    sectionIndex: { type: Number, default: 0 },
    fieldIndex: { type: Number, default: 0 }
  },
  data: () => ({
    fileinput: null,
    loading: false,
    filetypes: [
      {
        selected: false,
        text: 'Document (Spreadsheet,Presentation,PDF, etc)',
        value: 'document',
        supported: [
          '.txt',
          '.doc',
          '.docx',
          '.xls',
          '.xlsx',
          '.pdf',
          'application/msword',
          '.ppt',
          '.pptx',
          'application/vnd.openxmlformats-officedocument.wordprocessingml.document'
        ]
      },
      {
        selected: false,
        text: 'Image Files',
        value: 'image',
        supported: ['image/*']
      },
      {
        selected: false,
        text: 'Audio and Video Files',
        value: 'video',
        supported: ['video/*', 'audio/*']
      },
      {
        selected: false,
        text: 'Compressed Files',
        value: 'compressed',
        supported: ['.zip', '.rar']
      }
    ]
  }),
  watch: {
    value: {
      handler: function (val) {
        this.structures = val
      },
      deep: true,
      immediate: true
    },
    structures: {
      handler: function (val) {
        this.$emit('input', val)
      },
      deep: true,
      immediate: true
    },
    fileinput: {
      handler: function (val) {
        this.mapToValue(val)
      },
      deep: true,
      immediate: true
    }
  },
  computed: {
    ...mapGetters('forms', ['label_color']),
    structures() {
      return this.$store.getters['forms/get_section_items_by_indexes'](
        this.sectionIndex,
        this.fieldIndex
      )
    },
    acceptedTypesArray() {
      let accepted =
        this.structures.accept && this.structures.accept.length
          ? this.structures.accept
          : ['*']
      let map = this.filetypes
        .filter((i) => accepted.includes(i.value))
        .map((i) => i.supported)
      return [].concat.apply([], map)
    },
    acceptedTypes() {
      return this.acceptedTypesArray.join(',')
    },
    fieldValue: {
      get() {
        return this.structures.value
      },
      set(val) {
        this.$store.commit('forms/update_section_item_prop', {
          sx: this.sectionIndex,
          ix: this.fieldIndex,
          prp: 'value',
          val: val
        })
      }
    }
  },
  methods: {
    validFileSizeRule(v) {
      let valid = !v || this.validFileSize(v)
      return (v) =>
        valid || `File size exceed ${this.settings.fileSizeMaxUploadInMB}MB`
    },
    validFileExtOrTypeRule(v) {
      let valid = !v || this.validFileExt(v) || this.validFileType(v)
      let message = v
        ? `File type ${v.type} is not allowed`
        : 'File type is not allowed'
      return (v) => valid || message
    },
    validFileType(file) {
      if (
        this.acceptedTypesArray.length === 0 ||
        this.acceptedTypesArray.includes('*')
      ) {
        return true
      }
      if (!file || !file.type) return false
      if (file.type.includes('image/')) {
        return this.acceptedTypesArray.includes('image/*')
      } else if (file.type.includes('video/')) {
        return this.acceptedTypesArray.includes('video/*')
      } else if (file.type.includes('audio/')) {
        return this.acceptedTypesArray.includes('audio/*')
      }
      return this.acceptedTypesArray.includes(file.type)
    },
    validFileExt(file) {
      if (
        this.acceptedTypesArray.length === 0 ||
        this.acceptedTypesArray.includes('*')
      )
        return true
      if (!file) return false
      let ext = file.name.split('.').pop()
      return ext && this.acceptedTypesArray.includes(`.${ext}`)
    },
    validFileSize(file) {
      return file && file.size <= this.settings.fileSizeMaxUploadLimit
    },
    clearInput() {
      if (this.structures) {
        this.fieldValue = null
      }
      this.fileinput = null
    },
    mapToValue(file) {
      if (!file) {
        if (this.structures) {
          this.fieldValue = null
        }
        return
      }
      if (
        this.validFileSize(file) &&
        (this.validFileExt(file) || this.validFileType(file))
      ) {
        this.loading = true
        let formData = new FormData()
        formData.append('file', file)
        request
          .post(`api2/attachments/dropzone`, formData)
          .then(({ data }) => {
            this.fieldValue = data
          })
          .finally(() => (this.loading = false))
      } else {
        this.clearInput()
      }
    }
  }
}
</script>

<style lang="scss" scoped></style>
