<template>
  <v-sheet
    v-bind="$attrs"
    class="pa-3 question-checkbox fullwidth d-flex flex-column align-center justify-start"
  >
    <v-row dense align="center" justify="start" class="py-2 fullwidth">
      <v-col cols="12" class="d-flex fullwidth align-center justify-start">
        <v-checkbox
          hide-details="auto"
          class="my-0"
          v-model="fieldValue"
          :key="structures.label"
          :true-value="structures.true_value"
          :false-value="structures.false_value"
          :placeholder="structures.placeholder"
          :label="structures.label"
        >
        </v-checkbox>
      </v-col>
    </v-row>
  </v-sheet>
</template>

<script>
import _ from 'lodash'
import ContentEditable from '@/common/Forms/ContentEditable.vue'
import { mapGetters } from 'vuex'
export default {
  components: { ContentEditable },
  inheritAttrs: false,
  name: 'QuestCheckbox',
  props: {
    sectionIndex: { type: Number, default: 0 },
    fieldIndex: { type: Number, default: 0 }
  },
  computed: {
    ...mapGetters('forms', ['label_color']),
    structures() {
      return this.$store.getters['forms/get_section_items_by_indexes'](
        this.sectionIndex,
        this.fieldIndex
      )
    },
    fieldValue: {
      get() {
        return this.structures.value
      },
      set(val) {
        this.$store.commit('forms/update_section_item_prop', {
          sx: this.sectionIndex,
          ix: this.fieldIndex,
          prp: 'value',
          val: val
        })
      }
    }
  }
}
</script>

<style lang="scss">
.question-checkbox {
  .v-input--checkbox {
    label {
      color: $blue;
      font-size: 16px;
      font-weight: 500;
    }
  }
}
</style>
