<template>
  <v-sheet v-bind="$attrs" class="pa-3 fullwidth d-flex flex-column">
    <v-row dense align="center" justify="space-between">
      <v-col cols="12" md="12" sm="12" class="fullwidth d-flex align-center">
        <v-avatar
          tile
          :class="[alignClass]"
          :height="structures.itemheight"
          :width="structures.itemwidth"
          :key="`${structures.itemwidth}-${structures.itemheight}-${alignClass}`"
        >
          <v-img
            :lazy-src="settings.loader"
            contain
            :src="structures.src"
          ></v-img>
        </v-avatar>
      </v-col>
    </v-row>
  </v-sheet>
</template>

<script>
import _ from 'lodash'
export default {
  inheritAttrs: false,
  name: 'ShowImage',
  props: {
    sectionIndex: { type: Number, default: 0 },
    fieldIndex: { type: Number, default: 0 },
    item: Object
  },
  computed: {
    structures() {
      if (this.item) return this.item
      return this.$store.getters['forms/get_section_items_by_indexes'](
        this.sectionIndex,
        this.fieldIndex
      )
    },
    alignClass() {
      if (this.structures && this.structures.align === 'right') return 'ml-auto'
      else if (this.structures && this.structures.align === 'center')
        return 'mx-auto'
      else return 'mr-auto'
    }
  }
}
</script>

<style></style>
