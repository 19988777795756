import { render, staticRenderFns } from "./QuestionTime.vue?vue&type=template&id=6bbb36f4&scoped=true&"
import script from "./QuestionTime.vue?vue&type=script&lang=js&"
export * from "./QuestionTime.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6bbb36f4",
  null
  
)

export default component.exports