<template>
  <v-sheet
    v-bind="$attrs"
    class="form-short-text pa-3 fullwidth d-flex flex-column align-center justify-start"
  >
    <label class="align-self-start mb-1">
      <ContentEditable
        :editable="false"
        :show-edit="false"
        :style="`color:${label_color};`"
        class="fw-500 fs-15"
        :value="structures.label"
      ></ContentEditable>
    </label>
    <v-row dense align="center" justify="start" class="fullwidth">
      <v-col cols="12" class="d-flex fullwidth align-center justify-center">
        <v-text-field
          v-if="structures.type === 'text'"
          hide-details="auto"
          outlined
          dense
          :rules="[structures.required ? requiredRule() : optionalRule()]"
          v-model="fieldValue"
          :type="structures.type"
          :placeholder="structures.placeholder"
          :readonly="readOnly"
        ></v-text-field>
        <v-text-field
          v-else-if="structures.type === 'number'"
          hide-details="auto"
          outlined
          dense
          :min="structures.min"
          :max="structures.max"
          :rules="[
            structures.required ? requiredRule() : optionalRule(),
            numericRule(),
            minRule(structures.min),
            maxRule(structures.max)
          ]"
          v-model="fieldValue"
          :type="structures.type"
          :placeholder="structures.placeholder"
          :readonly="readOnly"
        ></v-text-field>
        <v-text-field
          v-else-if="structures.type === 'email'"
          hide-details="auto"
          outlined
          dense
          :rules="[
            structures.required ? requiredRule() : optionalRule(),
            emailRule()
          ]"
          v-model="fieldValue"
          :type="structures.type"
          :placeholder="structures.placeholder"
          :readonly="readOnly"
        ></v-text-field>
        <v-text-field
          v-else-if="structures.type === 'url'"
          hide-details="auto"
          outlined
          dense
          :rules="[
            structures.required ? requiredRule() : optionalRule(),
            urlRule()
          ]"
          v-model="fieldValue"
          :type="structures.type"
          :placeholder="structures.placeholder"
          :readonly="readOnly"
        ></v-text-field>
      </v-col>
    </v-row>
  </v-sheet>
</template>

<script>
import _ from 'lodash'
import ContentEditable from '@/common/Forms/ContentEditable.vue'
import { mapGetters } from 'vuex'
export default {
  components: { ContentEditable },
  inheritAttrs: false,
  name: 'QuestionShortText',
  props: {
    sectionIndex: { type: Number, default: 0 },
    fieldIndex: { type: Number, default: 0 },
    readOnly: { type: Boolean, default: false }
  },
  data: () => ({
    //
  }),
  computed: {
    ...mapGetters('forms', ['label_color']),
    structures() {
      return this.$store.getters['forms/get_section_items_by_indexes'](
        this.sectionIndex,
        this.fieldIndex
      )
    },
    fieldValue: {
      get() {
        return this.structures.value
      },
      set(val) {
        this.$store.commit('forms/update_section_item_prop', {
          sx: this.sectionIndex,
          ix: this.fieldIndex,
          prp: 'value',
          val: val
        })
      }
    }
  }
}
</script>

<style lang="scss" scoped></style>
